import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";

import { Icon } from "semantic-ui-react";

// UI
import HospitalConsentContent from "./HospitalConsentContent";
import HeaderConsentForm from "./HeaderConsentForm";
import HeaderConsentForm2 from "./HeaderConsentForm2";

import CONFIG from "../config/config";

// Types
type ConsentFormHospitalProps = {
  apiToken: string;
  controller: any;
  match: any;
  patientData: any;
  consentFormLang: string;
  consentsList?: any;
  notDisable?: boolean;
  // callback
  noApiToken: any;
  onFormLanguageChange: (lang: string) => any;
  onSetBackToApp: (value: boolean) => any;
  onGoBack: Function;
  onFormFinished?: Function;
};

const ConsentFormHospital = (props: ConsentFormHospitalProps) => {
  const [selectHospital, setSelectHospital] = useState<any>();
  const [selectHospitalCode, setSelectHospitalCode] = useState<any>();
  const [selectHospitalName, setSelectHospitalName] = useState<any>();
  const [isTelemed, setIsTelemed] = useState<boolean | undefined>();
  const [isTelemedFinished, setIsTelemedFinished] = useState<
    boolean | undefined
  >();
  const [publishedDiagRule, setPublishedDiagRule] = useState<string | number>(
    ""
  );
  const [consentFormParams, setConsentFormParams] = useState<Record<
    string,
    any
  > | null>(null);
  const [modalOnRefuse, setModalOnRefuse] = useState<boolean>(false);
  const [organizationName, setOrganizationName] = useState<string>("");

  const isMounted = useRef(true);
  const isTelemedRef = useRef<boolean | undefined>();
  const isTelemedDiagRule = useRef<string | number>();

  const history = useHistory<any>();

  useEffect(() => {
    if (props.match.params.page || props.match.params.page !== 1) {
      const params = new URLSearchParams(window.location.search);
      console.log("saika ~ params:", params)
      const select_hospital = params.get("select_hospital");
      const select_hospital_code = params.get("select_hospital_code");
      const select_hospital_name = params.get("select_hospital_name");
      const telemed = params.get("telemed");
      const go_to_telemed = JSON.parse(params.get("go_to_telemed") || "false");
      const published_diag_rule = params.get("published_diag_rule");
      const organization_name = params.get("organization_name");
      const publishDiagRule = Number(published_diag_rule) || "";
      const telemed_finished = JSON.parse(
        params.get("telemed_finished") || "false"
      );

      const telemed_diag_rule = params.get("telemed_diag_rule");
      const telemedDiagRule = Number(telemed_diag_rule) || CONFIG.TELEMED_CONSENT_DIAG_RULE;
      
      setSelectHospital(JSON.parse(select_hospital || "false"));
      setSelectHospitalCode(select_hospital_code);
      setSelectHospitalName(select_hospital_name);
      isTelemedRef.current = JSON.parse(telemed || "false");
      isTelemedDiagRule.current = telemedDiagRule
      setIsTelemed(go_to_telemed);
      setPublishedDiagRule(publishDiagRule);
      setIsTelemedFinished(telemed_finished);
      setOrganizationName(organization_name || "");

      if (isTelemedRef.current) {
        setModalOnRefuse(true);
      }

      history.replace({
        pathname: "/consent-hospital-form/" + props.match.params.id + "/",
        state: history.location.state,
      });
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClose = useCallback(() => {

    if (history.location.state) {
      gotoAppointment()
    } else {
      const page = props.match.params.page ?? 1;
      if (typeof window.iosNative !== "undefined") {
        window.iosNative?.dismissWebView?.()
      } else if (typeof window.MobNative !== "undefined") {
        window.MobNative?.dismissWebView?.()
      } else if (typeof window.mobile !== "undefined") {
        window.mobile?.dismissWebView?.()
      }
    }

   
    // history.go(-page);
  }, [consentFormParams, props.match.params.page]);

  const firstPage = useMemo(() => {
    return !props.match.params.page || props.match.params.page < 1;
  }, [props.match.params.page]);

  const leftItem = useMemo(() => {
    const isGoBack = !(
      (!!history.location.state || isTelemed) &&
      !props.match?.params?.page
    );

    return (
      typeof isTelemed === "boolean" &&
      isGoBack && <GoBackButton onClick={props.onGoBack} />
    );
  }, [isTelemed, history.location.state, props.match?.params?.page]);

  const middleItem = useMemo(() => {
    const telemedHeader = {
      "th-TH": [
        "หนังสือแสดงความยินยอมการรักษา",
        "แบบการแพทย์ทางไกลหรือโทรเวช",
        "(Telemedicine)",
      ],
      "en-US": [
        "หนังสือแสดงความยินยอมการรักษา",
        "แบบการแพทย์ทางไกลหรือโทรเวช",
        "(Telemedicine)",
      ],
    }[props.consentFormLang];

    const consentHeader = {
      "th-TH": ["การยินยอมให้เปิดเผยข้อมูล"],
      "en-US": ["Consent Form"],
    }[props.consentFormLang];

    const headers = isTelemed ? telemedHeader : consentHeader;

    return (
      <div
        className=""
        style={
          isTelemed
            ? { lineHeight: 1.4, fontSize: "large" }
            : { lineHeight: 1.2 }
        }
      >
        {headers?.map((header) => (
          <div key={header}>{header}</div>
        ))}
      </div>
    );
  }, [isTelemed, props.consentFormLang]);

  const rightItem = useMemo(() => {
    return (
      isTelemed && <Icon name={"close"} size={"large"} onClick={handleClose} />
    );
  }, [isTelemed, props.match.params.page]);

  const handleFormLanguageChange = (lang: string) => {
    let language = "th-TH";
    if (
      lang &&
      typeof lang === "string" &&
      ["en", "en-us", "eng", "english"].includes(lang.toLowerCase())
    ) {
      language = "en-US";
    }

    props.onFormLanguageChange(language);
  };

  const handleGoToTelemedPage = (params: any) => {
    if (params) {
      setConsentFormParams(params);
      setIsTelemed(true);
      console.log("saika ~ isTelemedDiagRule:", isTelemedDiagRule)
      return handleHistoryPushState({
        pathname: `/consent-hospital-form/${isTelemedDiagRule.current}`,
      });
    } else {
      setConsentFormParams(null);
      setIsTelemed(false);
    }
  };

  const handleHistoryPushState = (params: any) => {
    let state = history.location.state;
    const pathname = history.location.pathname;

    if (state || params.state) {
      state = { ...JSON.parse(state), ...params.state };

      state.routes = [...(state.routes || []), pathname];

      if (!params.reload) {
        state = JSON.stringify(state);
      }
    }

    const data = { pathname: params.pathname, state: state } as any;

    if (params.search) {
      data.search = params.search;
    }

    history.push(data);

    if (params.reload) {
      globalThis.history.go();
    }
  };

  const handleCloseConsent = () => {
    if (history.location.state) {
      gotoAppointment()
    } else {
      props.onGoBack()
    }
  }

  const checkIsAppointment = () => {
    if (history.location.state ) {
      return true
    } else {
      return props.notDisable
    }
  }

  const gotoAppointment= () => {
    const state = history.location.state;
    const locState = JSON.parse(state);
    const index = (locState.routes || [])
    .reverse()
    .findIndex((route: string) => route === "/confirm-appointment");
    history.go(-(index + 1));

    setTimeout(() => {
      history.replace({
        pathname: `/confirm-appointment`,
        search: `?app=MobSmartAppointment`,
        state,
      });

      globalThis.history.go();
    }, 10);
  }

  return (
    <HospitalConsentContent
      match={props.match}
      // Element
      menuBar={
        firstPage ? (
          <HeaderConsentForm2
            title={
              props.consentFormLang === "en-US"
                ? "Consent Form"
                : "การยินยอมให้เปิดเผยข้อมูล"
            }
            onGoBack={handleCloseConsent}
            notDisable={checkIsAppointment()}
          />
        ) : (
          <HeaderConsentForm
            hideRightItem
            leftItem={leftItem}
            middleItem={middleItem}
            rightClose={rightItem}
          />
        )
      }
      apiToken={props.apiToken}
      // satate
      selectHospital={selectHospital}
      selectHospitalCode={selectHospitalCode}
      selectHospitalName={selectHospitalName}
      isTelemed={isTelemedRef.current}
      publishedDiagRule={publishedDiagRule}
      consentFormParams={consentFormParams}
      isTelemedFinished={isTelemedFinished}
      organizationName={organizationName}
      consentsList={props.consentsList}
      // data
      patientData={props.patientData}
      // controller
      controller={props.controller}
      // config
      hideAgreeButton={isTelemed}
      modalOnRefuse={modalOnRefuse && !isTelemed}
      // callback
      noApiToken={props.noApiToken}
      onFormLanguageChange={handleFormLanguageChange}
      onSetBackToApp={props.onSetBackToApp}
      onGoToTelemedPage={handleGoToTelemedPage}
      onFormFinished={props.onFormFinished}
      onHistoryPushState={handleHistoryPushState}
    />
  );
};

/* ------------------------------------------------------ */

/*                      GoBackButton;                     */

/* ------------------------------------------------------ */
export const GoBackButton = (props: any) => {
  return <Icon name={"arrow left"} size={"large"} onClick={props.onClick} />;
};

export default React.memo(ConsentFormHospital);
